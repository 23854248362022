<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="EAP_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="emergencyTypeCd"
            label="비상사태구분"
            v-model="searchParam.emergencyTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag1"
            label="비상조치 사용여부"
            v-model="searchParam.useFlag1"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag2"
            label="시나리오 사용여부"
            v-model="searchParam.useFlag2"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="비상조치계획서 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="sopEmergencyPlanId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="onItemClick" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'emergenct-action-plan-docu',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: "mergeGroup" },
          { index: 1, colName: "mergeGroup" },
          { index: 2, colName: "mergeGroup" },
          { index: 3, colName: "mergeGroup" },
          { index: 4, colName: "mergeGroup" },
          { index: 5, colName: "mergeGroup" }
        ],
        columns: [
          {
            name: 'planDocu',
            field: 'planDocu',
            label: '비상조치계획',
            align: 'center',
            child: [
              {
                name: 'plantName',
                field: 'plantName',
                label: '사업장',
                align: 'center',
                sortable: false,
                style: 'width:120px',
              },
              {
                name: 'emergencyTypeName',
                field: 'emergencyTypeName',
                label: '비상사태구분',
                align: 'center',
                sortable: false,
                style: 'width:100px',
              },
              {
                name: 'emergencyPlanName',
                field: 'emergencyPlanName',
                label: '계획서명',
                align: 'left',
                sortable: false,
                type: 'link',
                style: 'width:150px',
              },
              {
                name: 'documentNo',
                field: 'documentNo',
                label: '문서번호',
                align: 'center',
                sortable: false,
                style: 'width:50px',
              },
              {
                name: 'emergencyClassCd',
                field: 'emergencyClassCd',
                label: '비상사태유형',
                align: 'center',
                sortable: false,
                style: 'width:150px',
              },
              {
                name: 'useFlagName1',
                field: 'useFlagName1',
                label: '사용여부',
                align: 'center',
                sortable: false,
                style: 'width:50px',
              },
            ]
          },
          {
            name: 'traningScnario',
            field: 'traningScnario',
            label: '훈련시나리오',
            align: 'center',
            child: [
              {
                name: 'trainingScenarioName',
                field: 'trainingScenarioName',
                label: '훈련시나리오명',
                align: 'left',
                sortable: false,
                style: 'width:150px',
              },
              {
                name: 'revisionNum',
                field: 'revisionNum',
                label: 'Rev.',
                align: 'center',
                sortable: false,
                style: 'width:40px',
              },
              {
                name: 'trainingContentsSummary',
                field: 'trainingContentsSummary',
                label: '훈련내용요약',
                align: 'left',
                sortable: false,
                style: 'width:250px',
              },
              {
                name: 'useFlagName2',
                field: 'useFlagName2',
                label: '사용여부',
                align: 'center',
                sortable: false,
                style: 'width:50px',
              },
            ]
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        emergencyTypeCd: null,
        useFlag1: 'Y',
        useFlag2: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // role setting
      this.listUrl = selectConfig.sop.eap.planDocu.list.url;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    onItemClick() {
      this.linkClick();
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      // 비상조치계획서 상세 팝업
      this.popupOptions.title = '비상조치계획서 상세'; 
      this.popupOptions.param = {
        sopEmergencyPlanId: row ? row.sopEmergencyPlanId : '',
      };
      this.popupOptions.target = () => import(`${'./emergencyActionPlanDocuDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
